<template>
  <div class="procedure">
    <img src="https://file.senlianshop.com/orderStatus/zjlc_pic.png" alt="" srcset="">
  </div>
</template>
<script>
</script>
<style lang="scss">
.procedure {
  width: 100%;
  > img {
    width: 100%;
  }
}
</style>
